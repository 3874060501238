import Embed from 'quill/blots/embed'

export class FormatAnchorItalic extends Embed {
  static override blotName = 'format-anchor-italic'
  static override tagName = 'div'

  static override create(value: boolean): HTMLElement {
    const node = super.create(value) as HTMLDivElement
    node.classList.add('ql-format-anchor')
    node.classList.add('d-inline-block')
    node.innerText = '__'
    node.setAttribute('contenteditable', 'false')
    return node
  }
}

export type ReplaceablePlaceholderType =
  | 'placeholder'
  | 'dc-user-mention'
  | 'dc-role-mention'
  | 'dc-channel'
  | 'dc-emoji'
  | 'dc-custom-emoji'

export interface ReplaceablePlaceholder {
  type: ReplaceablePlaceholderType
  matchRegex: RegExp
  matchRegexGlobal: RegExp
  acceptMatch: (match: string) => boolean
  embedDeserialize: (match: string) => FormatPlaceholderData
  embedSerialize: (data: FormatPlaceholderData) => string
}

export interface FormatPlaceholderData {
  type: ReplaceablePlaceholderType
  value: string
  real_value: string
  text_color?: string
  suggestion_name?: string
  suggestion_description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customEmojiId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customEmojiAnimated?: boolean
}

export class FormatPlaceholder extends Embed {
  static override blotName = 'format-placeholder'
  static override tagName = 'span'

  static override create(value: FormatPlaceholderData): HTMLElement {
    const node = super.create(value) as HTMLSpanElement

    if (value.type === 'placeholder') {
      node.classList.add('ql-format-placeholder')
      node.classList.add('d-inline-block')
      node.innerText = value.value
    } else if (value.type === 'dc-user-mention') {
      node.classList.add('ql-format-dc-user-mention')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-role-mention') {
      node.classList.add('ql-format-dc-role-mention')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-channel') {
      node.classList.add('ql-format-dc-channel')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-emoji') {
      node.classList.add('ql-format-dc-emoji')
      node.classList.add('d-inline-block')
      node.innerHTML = twemoji.parse(value.value, {
        folder: 'svg',
        ext: '.svg'
      })
      node.setAttribute('title', value.real_value ?? '')
    } else if (value.type === 'dc-custom-emoji') {
      const animated =
        (value.customEmojiAnimated as unknown as string) == 'true' ||
        value.customEmojiAnimated === true
      const id = value.customEmojiId ?? ''
      node.classList.add('ql-format-dc-custom-emoji')
      node.classList.add('d-inline-block')
      node.innerHTML =
        '<img class="emoji" src="https://cdn.discordapp.com/emojis/' +
        id +
        (animated ? '.gif' : '.png') +
        '" />'
      node.setAttribute('title', value.real_value ?? '')
    }

    node.setAttribute('contenteditable', 'false')
    node.setAttribute('qj-value', value.value)
    node.setAttribute('qj-real_value', value.real_value)
    node.setAttribute('qj-type', value.type)
    node.setAttribute('qj-suggestion_name', value.suggestion_name ?? '')

    if (value.customEmojiId !== undefined) {
      node.setAttribute('qj-custom_emoji_id', value.customEmojiId)
    }
    if (value.customEmojiAnimated !== undefined) {
      node.setAttribute(
        'qj-custom_emoji_animated',
        value.customEmojiAnimated ? 'true' : 'false'
      )
    }

    if (value.text_color !== undefined) {
      node.setAttribute('qj-text_color', value.text_color)
      node.style.color = value.text_color
    }

    return node
  }

  static override value(node: HTMLElement): FormatPlaceholderData {
    return {
      value: node.getAttribute('qj-value') || '',
      real_value: node.getAttribute('qj-real_value') || '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: (node.getAttribute('qj-type') as any) || 'placeholder',
      text_color: node.hasAttribute('qj-text_color')
        ? node.getAttribute('qj-text_color') || undefined
        : undefined,
      suggestion_name: node.getAttribute('qj-suggestion_name') || '',
      customEmojiId: node.getAttribute('qj-custom_emoji_id') || undefined,
      customEmojiAnimated:
        node.getAttribute('qj-custom_emoji_animated') == 'true' ? true : false
    }
  }
}
