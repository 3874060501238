import {
  AfterContentInit,
  AfterViewInit,
  Component,
  HostListener,
  OnInit
} from '@angular/core'
import { LoginService } from './core/services/login.service'
import { LocaleService } from './core/services/locale.service'
import { LoaderService } from './core/services/loader.service'
import Quill from 'quill/core'
import { FormatAnchorItalic, FormatPlaceholder } from './quilljs/format-anchor'
import PlainClipboard from './quilljs/clipboard-module'
import 'quill-mention'
import moment from 'moment'
import * as Sentry from '@sentry/angular'

// Idiomas de moment js
import 'moment/locale/es'
import 'moment/locale/fr'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit, AfterViewInit {
  title = 'zergrosh-front-v2'
  performingAction = true

  windowWidth: number = 0

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth
  }

  constructor(
    private loginService: LoginService,
    private loaderService: LoaderService,
    locale: LocaleService
  ) {
    locale.load()

    if (!environment.production) {
      Sentry.captureException(
        new Error('ERROR QUE NO SE DEBE CAPTURAR A SENTRY POR SER AMBIENTE DEV')
      )
    }
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth
    this.performingAction = this.loaderService.getDisplay()

    Quill.register(FormatAnchorItalic)
    Quill.register(FormatPlaceholder)
    Quill.register(
      {
        'modules/clipboard': PlainClipboard
      },
      true
    )

    moment.locale('en', {
      calendar: {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        nextWeek: 'dddd [at] LT',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd [at] LT',
        sameElse: 'LLLL'
      }
    })

    moment.locale('es', {
      calendar: {
        lastDay: '[Ayer a las] LT',
        sameDay: '[Hoy a las] LT',
        nextDay: '[Mañana a las] LT',
        lastWeek: '[El] dddd [pasado a las] LT',
        nextWeek: 'dddd [a las] LT',
        sameElse: '[El] LLLL'
      },
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY h:mm A',
        LLLL: 'dddd D [de] MMMM [de] YYYY [a las] h:mm A'
      }
    })
  }

  ngAfterContentInit(): void {
    this.loaderService.onDisplayChange((loading) => {
      setTimeout(() => {
        this.performingAction = loading
      })
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const el = document.getElementById('globalLoader')
      if (el) {
        if (!el.classList.contains('global-loader-hidden')) {
          el.className += ' global-loader-fade-in'

          setTimeout(() => {
            document.body.removeChild(el)
          }, 500)
        }
      }
    }, 250)
  }
}
