import { Injectable, Injector } from '@angular/core'
import { LoginService } from '../services/login.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private injector: Injector
  ) {}

  canActivate(): boolean {
    const loginService = this.injector.get(LoginService)
    if (loginService.loginState.value === 'LOGGED') {
      return true
    } else {
      this.router.navigate(['/'])
      return false
    }
  }
}
