import Quill, { Delta } from 'quill/core'
import Clipboard from 'quill/modules/clipboard'
import type { Range } from 'quill/core/selection'
import { ReplaceablePlaceholderType } from './format-anchor'
import { EditorComponent } from '../core/components/utils/editor/editor.component'
import { retrieveEmojiUnicodeByShortName } from '../core/utils/app-util'

class PlainClipboard extends Clipboard {
  startAdding = false

  override onPaste(
    range: Range,
    {
      text,
      html
    }: {
      text?: string
      html?: string
    }
  ) {
    const delta = new Delta().retain(range.index).delete(range.length)

    if (html !== undefined && html.trim().length > 0) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')

      const editor = EditorComponent.getEditorInstanceByTarget(
        this.quill.container
      )

      this.loopNode(delta, doc.body, editor)
    } else if (text !== undefined) {
      delta.insert(text)
    }

    this.quill.updateContents(delta, Quill.sources.USER)
    this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT)
    this.quill.scrollSelectionIntoView()
  }

  loopNode(delta: Delta, node: Node, editor: EditorComponent | null) {
    node.childNodes.forEach((el) => {
      if (el.nodeType === Node.COMMENT_NODE) {
        const data = (el as Comment).data
        if (data === 'StartFragment') {
          this.startAdding = true
        } else if (data === 'EndFragment') {
          this.startAdding = false
        }
      } else if (el.nodeType === Node.TEXT_NODE && this.startAdding) {
        if (el.textContent?.length !== 0) {
          delta.insert(el.textContent ?? '')
        }
      } else if (this.startAdding) {
        const element = el as Element
        if (element.tagName === 'SPAN') {
          const span = el as HTMLSpanElement
          const qjValue = span.getAttribute('qj-value')
          const qjRealValue = span.getAttribute('qj-real_value')
          const qjType = span.getAttribute(
            'qj-type'
          ) as ReplaceablePlaceholderType | null
          const qjCustomEmojiId = span.getAttribute('qj-custom_emoji_id')
          const qjCustomEmojiAnimated = span.getAttribute(
            'qj-custom_emoji_animated'
          )

          if (qjValue === null || qjRealValue === null || qjType === null) {
            this.loopNode(delta, el, editor)
            return
          }

          if (
            qjType !== null &&
            editor !== null &&
            ((qjType === 'dc-channel' && !editor.allowChannelMention) ||
              (qjType === 'dc-user-mention' && !editor.allowUserMention) ||
              (qjType === 'dc-role-mention' && !editor.allowRoleMention) ||
              (qjType === 'dc-custom-emoji' && !editor.allowCustomEmojis) ||
              (qjType === 'dc-emoji' && !editor.allowUnicodeEmojis))
          ) {
            switch (qjType) {
              case 'dc-channel':
                delta.insert('<#' + qjRealValue + '>')
                break
              case 'dc-user-mention':
                delta.insert('<@' + qjRealValue + '>')
                break
              case 'dc-role-mention':
                delta.insert('<@&' + qjRealValue + '>')
                break
              case 'dc-custom-emoji':
                delta.insert(qjRealValue)
                break
              case 'dc-emoji': {
                const unicode = retrieveEmojiUnicodeByShortName(
                  qjRealValue.replaceAll(':', '')
                )
                if (unicode === null) {
                  delta.insert(qjRealValue)
                } else {
                  delta.insert(unicode)
                }
                break
              }
            }
          } else {
            if (editor != null && qjType !== null) {
              // Verificar si el editor puede procesar esto
              const toInsert = editor.handleCopiedPlaceholder(
                qjType,
                qjValue,
                qjRealValue,
                qjCustomEmojiId,
                qjCustomEmojiAnimated
              )
              if (toInsert === undefined) {
                delta.insert(qjRealValue)
              } else {
                delta.insert({
                  'format-placeholder': toInsert
                })
              }
            } else {
              delta.insert(qjRealValue)
            }
          }
        } else {
          this.loopNode(delta, el, editor)
        }
      }
    })
  }
}

export default PlainClipboard
