import { Injectable, Injector } from '@angular/core'
import { DashboardService } from '../services/dashboard.service'

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard {
  constructor(private injector: Injector) {}

  canDeactivate(): boolean {
    const dashboardService = this.injector.get(DashboardService)
    return !dashboardService.isSaving()
  }
}
